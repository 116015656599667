import {Link, useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import {ACCESS_TOKEN_KEY} from '../../api/client';

export default function Home() {
  document.body.classList.add('home');
  const navigate = useNavigate();

  useEffect(() => {
    // const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    // if (null !== accessToken) {
      navigate('/app');
    // }
  }, []);

  return (
    <>
      <div className="video-container">
        <video className="video" loop autoPlay muted playsInline>
          <source src="./images/video_home.mp4"/>
        </video>
      </div>
      <div className="site-content">

        <div className="container h-100">
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-12 col-md-7 text-center">

              <div className="site-branding_logo animate" data-animation-type="fadeIn">
                <img alt="logo" src="./images/logo.svg" title="Space The 360"/>
              </div>

              <h1 className="animate" data-animation-type="slide-up">The real 360º festival
                <br/><span className="color_green">welcome</span></h1>

              <p className="welcome animate" data-animation-type="fadeIn" data-animation-delay="200">Bienvenido a la
                app oficial de Space The 360 con la que podras acceder a toda la informacion y servicios en el
                festival.</p>

              <p className="animate" data-animation-type="fadeIn" data-animation-delay="300">
                <Link to="/registro" className="btn btn-primary w-100">Regístrate</Link>
              </p>
              <p className="animate" data-animation-type="fadeIn" data-animation-delay="300">
                <Link to="/login" className="link">Accede con tu cuenta</Link>
              </p>

            </div>
          </div>
        </div>

      </div>
    </>
  );
}
