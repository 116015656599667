import {NavLink} from 'react-router-dom';

export default function TabBar() {
  return (
    <div className="tab-bar">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-7 text-center">
            <ul>
              <li>
                <NavLink to="/app" end className="nav-item info">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                       x="0px" y="0px" viewBox="0 0 21 21" style={{enableBackground: 'new 0 0 21 21'}}
                       xmlSpace="preserve">
									<path className="st1" d="M10.5,0C8.4,0,6.4,0.6,4.7,1.8C2.9,2.9,1.6,4.6,0.8,6.5c-0.8,1.9-1,4-0.6,6.1s1.4,3.9,2.9,5.4
										c1.5,1.5,3.3,2.5,5.4,2.9c2,0.4,4.1,0.2,6.1-0.6c1.9-0.8,3.6-2.1,4.7-3.9c1.2-1.7,1.8-3.8,1.8-5.8c0-2.8-1.1-5.5-3.1-7.4
										C16,1.1,13.3,0,10.5,0z M10.5,19.2c-1.7,0-3.4-0.5-4.9-1.5c-1.4-1-2.6-2.3-3.2-3.9c-0.7-1.6-0.8-3.4-0.5-5.1
										c0.3-1.7,1.2-3.3,2.4-4.5s2.8-2.1,4.5-2.4c1.7-0.3,3.5-0.2,5.1,0.5c1.6,0.7,3,1.8,3.9,3.2c1,1.4,1.5,3.1,1.5,4.9
										c0,2.3-0.9,4.5-2.6,6.2S12.8,19.2,10.5,19.2z"/>
                    <path className="st1" d="M10.5,8.8H9.6C9.4,8.8,9.2,8.8,9,9C8.8,9.2,8.8,9.4,8.8,9.6s0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3h0.9v5.2
										c0,0.2,0.1,0.5,0.3,0.6s0.4,0.3,0.6,0.3s0.5-0.1,0.6-0.3s0.3-0.4,0.3-0.6v-5.2c0-0.5-0.2-0.9-0.5-1.2S11,8.8,10.5,8.8z"/>
                    <path className="st1"
                          d="M10.5,7c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3C9.8,4.4,9.2,5,9.2,5.7S9.8,7,10.5,7z"/>
								  </svg>
                </NavLink>
              </li>
              <li>
                <NavLink to="horarios" className="nav-item music" data-target="music">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                       x="0px" y="0px" viewBox="0 0 21 21" style={{enableBackground: 'new 0 0 21 21'}}
                       xmlSpace="preserve">
									<path className="st1" d="M19.7,0.8c-0.4-0.3-0.9-0.6-1.4-0.7c-0.5-0.1-1-0.1-1.5-0.1l-8,1.5c-1,0.2-1.9,0.7-2.6,1.5
											c-0.7,0.8-1,1.8-1,2.8v8.6C4.7,14.2,4.1,14,3.5,14c-0.7,0-1.4,0.2-1.9,0.6c-0.6,0.4-1,0.9-1.3,1.6c-0.3,0.6-0.3,1.3-0.2,2
											c0.1,0.7,0.5,1.3,1,1.8c0.5,0.5,1.1,0.8,1.8,1c0.7,0.1,1.4,0.1,2-0.2C5.5,20.5,6,20,6.4,19.4C6.8,18.9,7,18.2,7,17.5v-8
											c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.5,1-0.6L18.2,6c0.1,0,0.3,0,0.4,0s0.2,0.1,0.3,0.2s0.2,0.2,0.2,0.3s0.1,0.2,0.1,0.4v5
											c-0.5-0.3-1.1-0.5-1.8-0.5c-0.7,0-1.4,0.2-1.9,0.6s-1,0.9-1.3,1.6c-0.3,0.6-0.3,1.3-0.2,2c0.1,0.7,0.5,1.3,1,1.8s1.1,0.8,1.8,1
											c0.7,0.1,1.4,0.1,2-0.2c0.6-0.3,1.2-0.7,1.6-1.3c0.4-0.6,0.6-1.3,0.6-1.9V3.5c0-0.5-0.1-1-0.3-1.5C20.5,1.5,20.1,1.1,19.7,0.8z
											 M3.5,19.2c-0.3,0-0.7-0.1-1-0.3c-0.3-0.2-0.5-0.5-0.6-0.8c-0.1-0.3-0.2-0.7-0.1-1c0.1-0.3,0.2-0.7,0.5-0.9
											c0.2-0.2,0.6-0.4,0.9-0.5c0.3-0.1,0.7,0,1,0.1C4.5,16,4.8,16.2,5,16.5c0.2,0.3,0.3,0.6,0.3,1c0,0.5-0.2,0.9-0.5,1.2
											C4.4,19.1,4,19.2,3.5,19.2z M17.9,4.2L8.1,6.1C7.7,6.2,7.3,6.3,7,6.5V5.9c0-0.6,0.2-1.2,0.6-1.7C8,3.7,8.5,3.4,9.1,3.3l8-1.5
											c0.3,0,0.5,0,0.8,0c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.2,0.4,0.4,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.7v0.9C18.8,4.2,18.3,4.2,17.9,4.2z
											 M17.5,16.6c-0.3,0-0.7-0.1-1-0.3c-0.3-0.2-0.5-0.5-0.6-0.8c-0.1-0.3-0.2-0.7-0.1-1c0.1-0.3,0.2-0.7,0.5-0.9
											c0.2-0.2,0.6-0.4,0.9-0.5c0.3-0.1,0.7,0,1,0.1c0.3,0.1,0.6,0.4,0.8,0.6c0.2,0.3,0.3,0.6,0.3,1c0,0.5-0.2,0.9-0.5,1.2
											C18.4,16.4,18,16.6,17.5,16.6z"/>
								</svg>

                </NavLink>
              </li>
              <li>
                <NavLink to="mapa" className="nav-item location" data-target="location">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                       x="0px" y="0px" viewBox="0 0 21 21" style={{enableBackground: 'new 0 0 21 21'}}
                       xmlSpace="preserve">
									<path className="st1" d="M10.5,5.2c-0.7,0-1.4,0.2-1.9,0.6C8,6.2,7.5,6.8,7.3,7.4C7,8.1,6.9,8.8,7.1,9.4c0.1,0.7,0.5,1.3,1,1.8
										c0.5,0.5,1.1,0.8,1.8,1c0.7,0.1,1.4,0.1,2-0.2c0.6-0.3,1.2-0.7,1.6-1.3c0.4-0.6,0.6-1.3,0.6-1.9c0-0.9-0.4-1.8-1-2.5
										C12.3,5.6,11.4,5.2,10.5,5.2z M10.5,10.5c-0.3,0-0.7-0.1-1-0.3C9.2,10,9,9.7,8.9,9.4c-0.1-0.3-0.2-0.7-0.1-1
										C8.9,8.1,9,7.8,9.3,7.5C9.5,7.3,9.8,7.1,10.2,7c0.3-0.1,0.7,0,1,0.1c0.3,0.1,0.6,0.4,0.8,0.6c0.2,0.3,0.3,0.6,0.3,1
										c0,0.5-0.2,0.9-0.5,1.2C11.4,10.3,11,10.5,10.5,10.5z"/>
                    <path className="st1" d="M10.5,21c-0.7,0-1.5-0.2-2.1-0.5c-0.7-0.3-1.2-0.8-1.7-1.4c-3.3-4.6-5-8.1-5-10.3c0-2.3,0.9-4.6,2.6-6.2
											C5.9,0.9,8.2,0,10.5,0c2.3,0,4.6,0.9,6.2,2.6c1.6,1.6,2.6,3.9,2.6,6.2c0,2.2-1.7,5.7-5,10.3c-0.4,0.6-1,1.1-1.7,1.4
											C12,20.8,11.2,21,10.5,21z M10.5,1.9c-1.8,0-3.6,0.7-4.9,2c-1.3,1.3-2,3-2,4.9c0,1.8,1.7,5,4.7,9.2c0.3,0.4,0.6,0.6,1,0.8
											s0.8,0.3,1.2,0.3s0.9-0.1,1.2-0.3c0.4-0.2,0.7-0.5,1-0.8c3-4.1,4.7-7.4,4.7-9.2c0-1.8-0.7-3.6-2-4.9C14.1,2.6,12.3,1.9,10.5,1.9z"
                    />
								</svg>

                </NavLink>
              </li>
              {/*<li>
                <NavLink to="cuenta" className="nav-item user" data-target="user">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                       x="0px" y="0px" viewBox="0 0 21 21" style={{enableBackground: 'new 0 0 21 21'}}
                       xmlSpace="preserve">
									<path className="st1" d="M10.5,0C4.7,0,0,4.7,0,10.5C0,16.3,4.7,21,10.5,21C16.3,21,21,16.3,21,10.5C21,4.7,16.3,0,10.5,0z M7,18.5
											v-0.1c0-1.9,1.6-3.5,3.5-3.5c1.9,0,3.5,1.6,3.5,3.5v0.1c-1.1,0.5-2.3,0.7-3.5,0.7C9.3,19.2,8.1,19,7,18.5z M15.7,17.5
											c-0.4-2.5-2.6-4.4-5.2-4.4c-2.6,0-4.8,1.9-5.2,4.4c-2.2-1.6-3.6-4.2-3.6-7c0-4.8,3.9-8.8,8.8-8.8c4.8,0,8.8,3.9,8.8,8.8
											C19.2,13.4,17.8,15.9,15.7,17.5z M10.5,4.4C8.6,4.4,7,5.9,7,7.9s1.6,3.5,3.5,3.5c1.9,0,3.5-1.6,3.5-3.5S12.4,4.4,10.5,4.4z
											 M10.5,9.6c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8S11.5,9.6,10.5,9.6z"/>
								</svg>
                </NavLink>
              </li>*/}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
