import {Link, useOutletContext} from 'react-router-dom';
import {Fragment, useEffect, useState} from 'react';

export default function Info() {
  const {info} = useOutletContext();
  const [openElement, setOpenElement] = useState({});

  const changeOpenElement = (event, field) => {
    event.preventDefault();

    let openElementClone = {...openElement};
    openElementClone[field.label] = !openElement[field.label];
    setOpenElement(openElementClone);
  };

  useEffect(() => {
    let initialOpenState = {};
    info?.fields.forEach((field) => {
      initialOpenState[field.label] = false;
    });

    setOpenElement(initialOpenState);
  }, [info]);

  return (
    <div className="site-content div-info">

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-7 text-center">

            <div className="site-branding_logo animate" data-animation-type="fadeIn">
              <img alt="logo" src="/images/SPS-THE360_GOLDEN.png" title="Space The 360" width={185}/>
            </div>

            <div className="h1 animate" data-animation-type="fadeUp">
              <p>{info?.date}</p>
              <p>{info?.city}</p>
            </div>
          </div>

          <div className="col-12 col-md-7">

            <div className="cards">
              {null === info && 'Cargando...'}
              {null !== info && info.fields.map(field => {
                const inner = (
                  <>
                    <div className="tit">{field.label}</div>
                    <div className="text" dangerouslySetInnerHTML={{__html: field.textShort}}></div>
                  </>);

                return (
                  <div className={`box animate${null !== field.url ? ' link' : ''}`} data-animation-type="fadeUp"
                       data-animation-delay="100" key={field.label}>
                    <div className="icon"><img alt={field.label} src={field.icon} className="img-fluid"/></div>
                    {null !== field.url
                      && <Link to={field.url} className="nav-item" target="_blank">{inner}</Link> }
                    {null !== field.textLong
                      && <a href="#"
                            className={`accordion-box${openElement[field.label] ? ' show' : ''}`}
                            onClick={(event) => changeOpenElement(event, field)}>
                        {inner}
                      </a>}
                    {null === field.url && null === field.textLong
                      && inner}

                    {null !== field.textLong
                      && <div className="box-item"
                              style={{display: openElement[field.label] ? 'block' : 'none'}}
                              dangerouslySetInnerHTML={{__html: field.textLong}}></div>}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
