import {createBrowserRouter} from 'react-router-dom';
import React from 'react';
import App from '../App';
import Info from '../pages/Info';
import Music from '../pages/Music';
import Home from '../pages/auth/Home';
import Register from '../pages/auth/Register';
import RegisterConfirmation from '../pages/auth/RegisterConfirmation';
import Login from '../pages/auth/Login';
import Map from '../pages/Map';
import Account from '../pages/Account';
import TextPrivacy from '../pages/TextPrivacy';
import TextLegal from '../pages/TextLegal';
import TextPrivacyPublic from '../pages/TextPrivacyPublic';
import TextLegalPublic from '../pages/TextLegalPublic';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Home/>
  },
  {
    path: '/registro',
    element: <Register/>
  },
  {
    path: '/registro/confirmacion',
    element: <RegisterConfirmation/>
  },
  {
    path: '/login',
    element: <Login/>,
  },
  {
    path: 'politica-privacidad',
    element: <TextPrivacyPublic/>
  },
  {
    path: 'aviso-legal',
    element: <TextLegalPublic/>
  },
  {
    path: '/app',
    element: <App />,
    children: [
      {
        index: true,
        element: <Info/>
      },
      {
        path: 'horarios',
        element: <Music/>
      },
      {
        path: 'mapa',
        element: <Map/>
      },
      // {
      //   path: 'cuenta',
      //   element: <Account/>
      // },
      {
        path: 'politica-privacidad',
        element: <TextPrivacy/>
      },
      {
        path: 'aviso-legal',
        element: <TextLegal/>
      },
    ]
  },
]);
