import TabBar from './components/app/TabBar';
import {Link, Outlet, useMatch, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {ACCESS_TOKEN_KEY, apiClient, ERRORS, REFRESH_TOKEN_KEY} from './api/client';

export default function App() {
  document.body.classList.add('app');
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const matchInfo = useMatch('/app');
  const matchMusic = useMatch('/app/horarios');
  const matchMap = useMatch('/app/mapa');
  const matchAccount = useMatch('/app/cuenta');
  const matchPrivacyText = useMatch('/app/politica-privacidad');
  const matchLegalText = useMatch('/app/aviso-legal');

  const loadData = async () => {
    // try {
      const info = await apiClient.get('info');
      setInfo(info);
      setLoading(false);
    // } catch (error) {
    //   if (ERRORS.UNAUTHORIZED === error.message) {
    //     localStorage.removeItem(ACCESS_TOKEN_KEY);
    //     localStorage.removeItem(REFRESH_TOKEN_KEY);
    //
    //     navigate('/');
    //   }
    // }
  };

  useEffect(() => {
    // const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    // if (null === accessToken) {
    //   navigate('/');
    // } else {
      loadData();
    // }
  }, []);

  return (
    <>
      <div className={`head-bar${null !== matchInfo ? '' : ' active'}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col text-center">
              {(null !== matchPrivacyText || null !== matchLegalText)
                && <Link to="/app/cuenta" className="back" data-target="info" id="nav-info">
                  <img alt="volver" src="/images/ico_back.svg" title="Back"/>
                </Link>
              }

              <h2>
                {null !== matchMusic && <>Artistas y horarios</>}
                {null !== matchMap && <>Mapa</>}
                {null !== matchAccount && <>Tu cuenta</>}
                {null !== matchPrivacyText && <>Política de privacidad</>}
                {null !== matchLegalText && <>Aviso legal</>}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        {false === loading && <Outlet context={{info}} />}
      </div>

      <TabBar />
    </>
  );
}
